import React from "react"

const IconLogo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 157 139.6"
    aria-hidden="true"
    {...props}
  >
    <circle cx="78.5" cy="70.2" r="63.4" fill="#DAF1F9" />
    <path
      d="M123.1 44.2c5.5-20.6 4-36.8-5.3-42.2 -9.4-5.4-24.2 1.4-39.3 16.5 -2.7-2.6-5.3-5.1-8-7.2C57.8 0.9 46.8-2.4 39.3 1.9 30.1 7.2 28.4 23.3 34 44.1c-20.7 5.6-34 15-34 25.8h3.7c0-9 12.5-17.1 31.3-22.1 2.1 7.1 5 14.6 8.6 22.4l3.3-1.6c-3.5-7.6-6.3-14.9-8.4-21.7 6.1-1.4 12.7-2.6 19.7-3.3 -2.3 3.4-4.5 7-6.7 10.8 -1.6 2.7-3.1 5.5-4.5 8.2l3.3 1.7c1.4-2.7 2.9-5.4 4.4-8.1 2.6-4.6 5.4-8.9 8.3-13.1 5-0.4 10.2-0.6 15.5-0.6S89 42.7 94 43.1c2.9 4.2 5.6 8.5 8.2 13.1s5 9.2 7.2 13.7c-2.2 4.5-4.6 9.1-7.2 13.7C95 96 86.9 107.1 78.5 116c-5.1-5.4-10.3-11.7-15.2-18.8l-3 2.1c5.1 7.4 10.4 13.9 15.6 19.4 -2.6 2.6-5.2 4.9-7.8 7 -11.2 9.1-21.1 12.4-27 8.9 -6.7-3.8-8.5-16.2-4.8-32.9l-3.6-0.8c-4 18.7-1.6 32.1 6.6 36.9 2.1 1.2 4.6 1.9 7.3 1.9 6.7 0 14.9-3.8 23.9-11.1 2.7-2.2 5.3-4.6 8-7.3 11.8 11.7 23.2 18.4 32 18.4 2.7 0 5.1-0.6 7.2-1.8 13-7.5 10.8-35.9-4.2-68 3.6-7.7 6.4-15.1 8.5-22.1 18.8 5 31.2 13.1 31.2 22.1h3.7C157 59.1 143.7 49.7 123.1 44.2zM115.9 5.1c7.8 4.5 8.6 19.3 3.5 38.1 -7.1-1.7-15-2.9-23.4-3.7 -4.8-6.8-9.8-13-14.9-18.4C94.9 7.4 108.2 0.7 115.9 5.1zM37.5 43.2c-5.1-19-4.1-33.7 3.6-38.1 6-3.4 15.8-0.2 27 8.9 2.6 2.1 5.2 4.5 7.7 7 -5 5.3-10.1 11.5-14.9 18.5C52.6 40.3 44.7 41.5 37.5 43.2zM78.5 38.8c-4.3 0-8.6 0.1-12.7 0.4 4.2-5.7 8.4-10.9 12.7-15.4 4.3 4.6 8.6 9.7 12.7 15.4C87.1 38.9 82.9 38.8 78.5 38.8zM115.9 134.7c-7.6 4.4-20.9-2.2-34.8-16 8.6-9.1 17-20.5 24.3-33.2 2.2-3.7 4.2-7.5 6-11.2C124.1 102.9 126.6 128.5 115.9 134.7zM111.5 65.5c-1.8-3.7-3.8-7.4-6-11.2 -2.1-3.7-4.4-7.3-6.7-10.8 7 0.7 13.6 1.9 19.6 3.3C116.6 52.8 114.3 59.1 111.5 65.5z"
      fill="#F15959"
    />
    <polygon points="96.9 97.6 117.5 94.9 117.4 46 96.9 46 " fill="#4BAEE2" />
    <rect x="96.8" y="37.4" width="21.2" height="5.3" fill="#6BC7EE" />
    <polyline
      points="70.8 82 70.8 20.5 63.5 23.5 63.5 41.5 63.5 71.9 "
      fill="#4BAEE2"
    />
    <polygon
      points="87 50.8 87 23.5 74.9 20.5 74.6 88.2 81.6 94.3 "
      fill="#6BC7EE"
    />
    <path
      d="M59.5 65.6l24 34.3 -26.9-2.2c0 0-19.8-3.3-19.6-3.9 0.2-0.6 6.3-17.4 6.3-17.4l11.5-13.3L59.5 65.6z"
      fill="#FFF"
    />
    <polygon
      points="58.5 58.9 55.9 56.1 53.5 58.9 53.5 97.8 58.5 97.8 "
      fill="#6BC7EE"
    />
    <rect x="83.7" y="57.2" width="19.1" height="3.6" fill="#4BAEE2" />
    <rect x="82.3" y="61.3" width="21.9" height="3.8" fill="#6BC7EE" />
    <polygon
      points="81.2 65.3 81.7 97.3 104.6 97 105 97 105 65.3 "
      fill="#4BAEE2"
    />
    <path
      d="M102.8 56.5c-0.2-4.7-4.4-8.8-9.5-8.8 -5.3 0-9.6 3.9-9.6 8.8"
      fill="#FFF"
    />
    <line x1="93.1" y1="56.5" x2="93.1" y2="51" fill="none" />
    <path
      d="M93.1 57.2c-0.4 0-0.7-0.3-0.7-0.7V51c0-0.4 0.3-0.7 0.7-0.7s0.7 0.3 0.7 0.7v5.5C93.8 56.9 93.5 57.2 93.1 57.2z"
      fill="#2160AD"
    />
    <line x1="92.6" y1="56" x2="89.1" y2="52.1" fill="none" />
    <path
      d="M92.6 56.8c-0.2 0-0.4-0.1-0.6-0.3l-3.5-4c-0.3-0.3-0.2-0.8 0.1-1 0.3-0.3 0.8-0.2 1 0.1l3.5 4c0.3 0.3 0.2 0.8-0.1 1C92.9 56.7 92.7 56.8 92.6 56.8z"
      fill="#2160AD"
    />
    <line x1="93.6" y1="56" x2="97.1" y2="52.1" fill="none" />
    <path
      d="M93.6 56.8c-0.2 0-0.3-0.1-0.5-0.2 -0.3-0.3-0.3-0.7-0.1-1l3.5-4c0.3-0.3 0.7-0.3 1-0.1 0.3 0.3 0.3 0.7 0.1 1l-3.5 4C94.1 56.7 93.9 56.8 93.6 56.8z"
      fill="#2160AD"
    />
    <path
      d="M89.8 95.2c-0.4 0-0.7-0.3-0.7-0.7v-2.4c0-0.4 0.3-0.7 0.7-0.7s0.7 0.3 0.7 0.7v2.4C90.6 94.9 90.3 95.2 89.8 95.2z"
      fill="#FFF"
    />
    <path
      d="M113.4 53c-0.4 0-0.7-0.3-0.7-0.7v-1.9c0-0.4 0.3-0.7 0.7-0.7s0.7 0.3 0.7 0.7v1.9C114.1 52.7 113.8 53 113.4 53z"
      fill="#FFF"
    />
    <path
      d="M89.8 89.6c-0.4 0-0.7-0.3-0.7-0.7V70h-2.8c-0.4 0-0.7-0.3-0.7-0.7s0.3-0.7 0.7-0.7h4.3v20.3C90.6 89.3 90.3 89.6 89.8 89.6z"
      fill="#FFF"
    />
    <path
      d="M79.4 32.3c-0.4 0-0.7-0.3-0.7-0.7v-4.9c0-0.4 0.3-0.7 0.7-0.7s0.7 0.3 0.7 0.7v4.9C80.1 32 79.8 32.3 79.4 32.3z"
      fill="#FFF"
    />
    <path
      d="M113.4 90.6c-0.4 0-0.7-0.3-0.7-0.7V72c0-0.4 0.3-0.7 0.7-0.7s0.7 0.3 0.7 0.7v17.9C114.1 90.3 113.8 90.6 113.4 90.6z"
      fill="#FFF"
    />
    <path
      d="M79.4 38.9c-0.4 0-0.7-0.3-0.7-0.7v-2.1c0-0.4 0.3-0.7 0.7-0.7s0.7 0.3 0.7 0.7v2.1C80.1 38.5 79.8 38.9 79.4 38.9z"
      fill="#FFF"
    />
    <path
      d="M113.4 68.5c-0.4 0-0.7-0.3-0.7-0.7V56c0-0.4 0.3-0.7 0.7-0.7s0.7 0.3 0.7 0.7v11.7C114.1 68.1 113.8 68.5 113.4 68.5z"
      fill="#FFF"
    />
    <path
      d="M118 43.3c0.6 0 1.3 0 1.3-0.6v-5.3c0-0.6-0.7-1.1-1.3-1.1h-6.7v-1.5c0-0.4 0-0.5-0.4-0.5h-2.6v-8.8c0-0.6-0.4-1-1-1s-1 0.4-1 1v8.8h-2.5c-0.4 0-0.5 0.1-0.5 0.5v1.5h-6.5c-0.6 0-0.5 0.5-0.5 1.1v5.3c0 0.6-0.1 0.6 0.5 0.6h1.5v1h-1.4c-0.6 0-0.6 0.8-0.6 1.4v1.3c-1-0.2-1.8-0.4-2.8-0.4 -1.9 0-4.2 0.5-5.2 1.3V23.6c0-0.5-0.4-0.9-0.9-1l-12.2-3c-0.3-0.1-0.4 0-0.7 0.2 -0.2 0.2-0.2 0.5-0.2 0.8v1.8h-3v-1.8c0-0.3 0-0.6-0.2-0.8 -0.3-0.2-0.5-0.2-0.8-0.1l-7.4 3c-0.4 0.2-0.6 0.5-0.6 0.9v44.3l-3-4.2V59c0-0.3 0-0.5-0.2-0.7l-2.6-2.8c-0.2-0.2-0.5-0.3-0.8-0.3 -0.3 0-0.6 0.1-0.7 0.4l-2.4 2.8c-0.2 0.2-0.4 0.4-0.4 0.6v4.8l-5.9 8.6c-1.3 2.9-2.7 5.8-3.8 8.7l9.7-13.8v7.3l-13 18.6c0.7 0.2 1.3 0.3 2 0.5l11-15.6v7.1l-7 9.7c-0.3 0.4-0.1 1.1 0.3 1.4 0.2 0.1 0.4 0.2 0.6 0.2 0.3 0 0.5-0.2 0.7-0.4l5.4-7.5v7.1c1 0.1 2 0.2 2 0.3V59.4l1.4-1.7 1.6 1.7v36.7c1 0.1 2 0.5 2 0.5v-7.8l5.8 8.1c0.8 0.1 1.7 0.1 2.6 0.2l-8.4-11.7v-7.8L73 97.3c0.8 0 1.5 0 2.4 0.1L59.3 74.2v-6.9l21.2 30c0.8 0 1.6 0 2.4-0.1l-1-1.4 0.2-29.4h22.1v29.2c0-0.1 1-0.2 2-0.3V65.4c0-0.5-1-0.9-1-1v-3c0-0.6-0.5-1-1.1-1h0.1v-3.1c0-0.1-0.2-0.3-0.3-0.4 0-0.1 0.1-0.2 0.1-0.4 -0.2-3.8-2.8-7-5.8-8.6v-1.5h19v46.9c0-0.2 1-0.3 2-0.5v-47c0-0.6-0.7-1.4-1.3-1.4h-0.7v-1H118zM70.3 78.4l-6-7.6V24.3l6-2.2V78.4zM82.7 57c-0.1 0-0.4 0.1-0.4 0.3v3.1c-0.6 0-1 0.4-1 1v3h-0.1c-0.6 0-1 0.4-1 1L80.1 93l-4.5-6.4 0.4-64.7 10.2 2.5v25.1c-2 1.8-3.4 4.3-3.4 7.1C82.9 56.7 82.6 56.9 82.7 57zM103.3 64.3h-20v-2h20V64.3zM102.3 60.3h-18v-2h18V60.3zM101.7 56.3h-17c0.2-4 3.9-7.5 8.5-7.5C97.6 48.8 101.4 52.3 101.7 56.3zM98.3 41.3v-3h19v3H98.3z"
      fill="#2160AD"
    />
    <path
      d="M36.3 101.8l-3.6-0.9c2.6-11.9 7.6-25.1 14.4-38.4l3.3 1.7C43.7 77.1 38.8 90.2 36.3 101.8zM78.5 100.8C34.5 100.8 0 87.1 0 69.7h3.7c0 14.9 34.3 27.4 74.8 27.4s74.8-12.6 74.8-27.4h3.7C157 87.1 122.5 100.8 78.5 100.8z"
      fill="#F15959"
    />
  </svg>
)

export default IconLogo
